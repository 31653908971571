import React, { useEffect, useRef, useState } from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Collapse,
  Badge
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import InfoIcon from '@mui/icons-material/Info'; // Icône pour la colonne "options"

import {
  recupereListeDemandes,
  recupereInfosClient,
  majCom,
  majComVisa,
  traitementAssurance
} from '../Services';

import { Demandes } from '../models/Demandes';
import DemandeActions from './DemandeActions';

const getFormattedDate = () => {
  const now = new Date();
  const day = now.getDate().toString().padStart(2, '0');
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const year = now.getFullYear();
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const ListeDemandes: React.FC<{ onSelectionDemande: (idVoyageur: number, chemin: string) => void }> = ({ onSelectionDemande }) => {
  const [demandes, setDemandes] = useState<Demandes[]>([]);
  const [filteredDemandes, setFilteredDemandes] = useState<Demandes[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const [actionsOpen, setActionsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>(''); 
  const [clientInfoOpen, setClientInfoOpen] = useState(false); 
  const [clientInfo, setClientInfo] = useState<any>(null); 

  const [commentaires, setCommentaires] = useState<string>('');
  const [allCommentaires, setAllCommentaires] = useState<string>(''); 
  const [commentairesVisa, setCommentairesVisa] = useState<string>('');

  const [openCommentaires, setOpenCommentaires] = useState(true);
  const [openCommentairesVisa, setOpenCommentairesVisa] = useState(true);

  const [insuranceDialogOpen, setInsuranceDialogOpen] = useState(false);
  const [insuranceId, setInsuranceId] = useState<number | null>(null);

  // ----- État et fonctions pour la gestion de l'affichage des "options" -----
  const [optionsDialogOpen, setOptionsDialogOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[] | null>(null);

  const handleOpenOptionsDialog = (optionsArray: string[]) => {
    setSelectedOptions(optionsArray);
    setOptionsDialogOpen(true);
  };

  const handleCloseOptionsDialog = () => {
    setOptionsDialogOpen(false);
    setSelectedOptions(null);
  };
  // -------------------------------------------------------------------------

  // Fonction pour confirmer le traitement de l'assurance
  const handleConfirmInsurance = async () => {
    if (insuranceId !== null) {
      try {
        await traitementAssurance(insuranceId);
        setInsuranceDialogOpen(false);
        setInsuranceId(null);
        reloadDemandes(); 
      } catch (error) {
        console.error("Erreur lors du traitement de l'assurance:", error);
      }
    }
  };

  const handleOpenInsuranceDialog = (id: number) => {
    setInsuranceId(id);
    setInsuranceDialogOpen(true);
  };

  const handleCloseInsuranceDialog = () => {
    setInsuranceDialogOpen(false);
    setInsuranceId(null);
  };

  const handleRowClick = (id: number, idVoyageur: number, chemin: string) => {
    setSelectedId(id);
    const demande = demandes.find(d => d.id === id);
    if (demande) {
      setAllCommentaires(demande.commentaires || '');
      setCommentairesVisa(demande.commentairesvisa || '');
    }
    onSelectionDemande(idVoyageur, chemin);
  };

  useEffect(() => {
    const fetchDemandes = async () => {
      try {
        const result = await recupereListeDemandes();
        setDemandes(result);
        setFilteredDemandes(result);
      } catch (error) {
        console.error('Erreur lors de la récupération des demandes:', error);
      }
    };

    fetchDemandes();
  }, []);

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const reloadDemandes = async () => {
    try {
      const result = await recupereListeDemandes();
      setDemandes(result);
      setFilteredDemandes(result);
      setSelectedIds([]);
      setSelectedEmail(null);
      setSearchTerm('');
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop = 0;
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des demandes:', error);
    }
  };

  const formatDate = (isoString: any) => {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleCheckboxChange = (demande: Demandes) => {
    if (demande.id !== null) {
      if (selectedEmail === null || selectedEmail === demande.email) {
        if (selectedIds.includes(demande.id)) {
          setSelectedIds(selectedIds.filter(id => id !== demande.id));
          if (selectedIds.length <= 1) {
            setSelectedEmail(null);
          }
        } else {
          setSelectedIds([...selectedIds, demande.id]);
          setSelectedEmail(demande.email);
        }
      }
    }
  };

  const handleOpenActions = () => {
    if (selectedIds.length > 0) {
      setActionsOpen(true);
    }
  };

  const handleCloseActions = () => {
    setActionsOpen(false);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = demandes.filter((demande) => {
      const formattedDateCreation = formatDate(demande.date_creation);
      const formattedDateDepart = formatDate(demande.datedepart);
      return (
        demande.id?.toString().includes(value) ||
        demande.nom.toLowerCase().includes(value) ||
        demande.prenom.toLowerCase().includes(value) ||
        demande.titre.toLowerCase().includes(value) ||
        demande.etat_visa.toLowerCase().includes(value) ||
        formattedDateCreation.includes(value) ||
        formattedDateDepart.includes(value)
      );
    });
    
    setFilteredDemandes(filtered);
  };

  const handleOpenClientInfo = async (email: string) => {
    try {
      const clientData = await recupereInfosClient(email);
      setClientInfo(clientData);
      setClientInfoOpen(true);
    } catch (error) {
      console.error('Erreur lors de la récupération des infos client:', error);
    }
  };

  const handleCloseClientInfo = () => {
    setClientInfoOpen(false);
    setClientInfo(null);
  };

  // Filtre complet
  const handleFilterComplet = () => {
    const filtered = demandes.filter(demande => demande.etat_visa === 'Formulaire complet' && demande.etat_paiement === '');
    setFilteredDemandes(filtered);
  };

  // Filtre en cours
  const handleFilterEnCours = () => {
    const filtered = demandes.filter(demande => demande.etat_visa === 'en cours');
    setFilteredDemandes(filtered);
  };

  // Filtre traité
  const handleFilterTraite = () => {
    const filtered = demandes.filter(demande => demande.etat_visa === 'traité');
    setFilteredDemandes(filtered);
  };

  // Filtre à faire
  const handleFilterAFaire = () => {
    const filtered = demandes.filter(demande => demande.etat_visa === 'Formulaire complet' && demande.etat_paiement === 'payé');
    setFilteredDemandes(filtered);
  };

  // Filtre Vérification éligibilité
  const handleFilterVerifEligible = () => {
    const filtered = demandes.filter(demande => demande.etat_visa === 'Vérification éligibilité');
    setFilteredDemandes(filtered);
  };

  // Mise à jour commentaires
  const handleSaveCommentaires = async () => {
    if (selectedId !== null) {
      const newCommentaire = `${getFormattedDate()} - ${commentaires}\n`;
      const updatedCommentaires = `${newCommentaire}${allCommentaires}`;
      setAllCommentaires(updatedCommentaires);
      setCommentaires('');

      const demande = demandes.find(d => d.id === selectedId);
      if (demande) {
        try {
          await majCom(demande.chemin, updatedCommentaires);
          reloadDemandes();
        } catch (error) {
          console.error('Erreur lors de la mise à jour des commentaires:', error);
        }
      }
    }
  };

  // Mise à jour commentaires visa
  const handleSaveCommentairesVisa = async () => {
    if (selectedId !== null) {
      const demande = demandes.find(d => d.id === selectedId);
      if (demande) {
        try {
          await majComVisa(demande.chemin, commentairesVisa);
          reloadDemandes();
        } catch (error) {
          console.error('Erreur lors de la mise à jour des commentaires visa:', error);
        }
      }
    }
  };

  return (
    <>
      {/* Boutons de filtre */}
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs>
          <Button variant="contained" color="primary" fullWidth onClick={handleFilterComplet}>
            Complet
          </Button>
        </Grid>
        <Grid item xs>
          <Button variant="contained" color="primary" fullWidth onClick={handleFilterEnCours}>
            En cours
          </Button>
        </Grid>
        <Grid item xs>
          <Button variant="contained" color="primary" fullWidth onClick={handleFilterTraite}>
            Traité
          </Button>
        </Grid>
        <Grid item xs>
          <Button variant="contained" color="primary" fullWidth onClick={handleFilterAFaire}>
            À faire
          </Button>
        </Grid>
        <Grid item xs>
          <Button variant="contained" color="primary" fullWidth onClick={handleFilterVerifEligible}>
            Vérification éligibilité
          </Button>
        </Grid>
      </Grid>

      <TextField
        label="Rechercher un dossier"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearch}
        fullWidth
        style={{ marginBottom: '20px' }}
      />

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenActions}
          disabled={selectedIds.length === 0}
          style={{ flex: 1, marginRight: '10px' }}
        >
          Actions
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={reloadDemandes}
          style={{ flex: 1 }}
        >
          Rafraîchir
        </Button>
      </div>

      <TableContainer ref={tableContainerRef} component={Paper} style={{ maxHeight: '350px', overflow: 'auto' }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Nº</TableCell>
              <TableCell>GC</TableCell>
              <TableCell>Date demande</TableCell>
              <TableCell>Pays</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Prénom</TableCell>
              <TableCell>Départ</TableCell>
              <TableCell>Paiement</TableCell>
              <TableCell>Etat</TableCell>
              <TableCell>Options</TableCell>
              <TableCell>Assurance</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Client/Assurance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDemandes.map((demande) => {
              // Split des "options" par ";"
              const splittedOptions = demande.options
                ? demande.options.split(';').filter(Boolean)
                : [];

              return (
                <TableRow
                  key={demande.id}
                  onClick={() => handleRowClick(demande.id!, demande.id_voyageur, demande.chemin)}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    backgroundColor: selectedId === demande.id ? '#ADD8E6' : 'inherit',
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedIds.includes(demande.id!)}
                      onChange={() => handleCheckboxChange(demande)}
                      disabled={selectedEmail !== null && selectedEmail !== demande.email}
                    />
                  </TableCell>
                  <TableCell>{demande.id}</TableCell>
                  <TableCell>{demande.nomsociete}</TableCell>
                  <TableCell>{formatDate(demande.date_creation)}</TableCell>
                  <TableCell>{demande.titre}</TableCell>
                  <TableCell>{demande.nom}</TableCell>
                  <TableCell>{demande.prenom}</TableCell>
                  <TableCell>{demande.datedepart}</TableCell>
                  <TableCell>{demande.etat_paiement}</TableCell>
                  <TableCell>
                    {demande.etat_visa === 'Formulaire complet' ? 'complet' : demande.etat_visa}
                  </TableCell>

                  {/* Colonne "Options" */}
                  <TableCell>
                    <IconButton onClick={(e) => {
                      e.stopPropagation(); // Évite la sélection de la ligne
                      handleOpenOptionsDialog(splittedOptions);
                    }}>
                      <Badge
                        badgeContent={splittedOptions.length}
                        color={splittedOptions.length > 0 ? 'primary' : 'default'}
                      >
                        <InfoIcon />
                      </Badge>
                    </IconButton>
                  </TableCell>
                  {/* Fin de la colonne "Options" */}

                  <TableCell>{demande.assurancevisa}</TableCell>
                  <TableCell>{demande.infosdemande}</TableCell>
                  <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={(e) => {
                      e.stopPropagation();
                      handleOpenClientInfo(demande.email);
                    }}>
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton onClick={(e) => {
                      e.stopPropagation();
                      handleOpenInsuranceDialog(demande.id!);
                    }}>
                      <AssignmentTurnedInIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialogue pour confirmer l'assurance */}
      <Dialog open={insuranceDialogOpen} onClose={handleCloseInsuranceDialog}>
        <DialogTitle>Confirmer le traitement de l'assurance</DialogTitle>
        <DialogContent>Êtes-vous sûr de vouloir marquer cette assurance comme traitée ?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInsuranceDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirmInsurance} color="secondary">
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogue pour afficher la liste des options */}
      <Dialog open={optionsDialogOpen} onClose={handleCloseOptionsDialog}>
        <DialogTitle>Options</DialogTitle>
        <DialogContent>
  {selectedOptions && selectedOptions.length > 0 ? (
    <div>
      {selectedOptions.map((option, index) => (
        <div key={index}>{option}</div>
      ))}
    </div>
  ) : (
    <p>Aucune option</p>
  )}
</DialogContent>

        <DialogActions>
          <Button onClick={handleCloseOptionsDialog}>Fermer</Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={2} style={{ marginTop: '20px' }}>
        <Grid item xs={6}>
          <Button
            variant="text"
            startIcon={<ExpandMoreIcon />}
            onClick={() => setOpenCommentaires(!openCommentaires)}
          >
            {openCommentaires ? 'Masquer Commentaires' : 'Afficher Commentaires'}
          </Button>
          <Collapse in={openCommentaires}>
            <TextField
              label="Commentaires existants"
              variant="outlined"
              fullWidth
              multiline
              rows={5}
              value={allCommentaires}
              InputProps={{ readOnly: true }}
              style={{ marginTop: '10px', maxHeight: '150px', overflow: 'auto' }}
            />

            <TextField
              label="Nouveau Commentaire"
              variant="outlined"
              fullWidth
              multiline
              rows={2}
              value={commentaires}
              onChange={(e) => setCommentaires(e.target.value)}
              style={{ marginTop: '15px' }}
            />
            <Button variant="contained" color="primary" onClick={handleSaveCommentaires} style={{ marginTop: '10px' }}>
              Enregistrer
            </Button>
          </Collapse>
        </Grid>

        <Grid item xs={6}>
          <Button
            variant="text"
            startIcon={<ExpandMoreIcon />}
            onClick={() => setOpenCommentairesVisa(!openCommentairesVisa)}
          >
            {openCommentairesVisa ? 'Masquer Commentaires Visa' : 'Afficher Commentaires Visa'}
          </Button>
          <Collapse in={openCommentairesVisa}>
            <TextField
              label="Commentaires visa"
              variant="outlined"
              fullWidth
              multiline
              rows={5}
              value={commentairesVisa}
              onChange={(e) => setCommentairesVisa(e.target.value)}
              style={{ marginTop: '10px' }}
            />
            <Button variant="contained" color="primary" onClick={handleSaveCommentairesVisa} style={{ marginTop: '10px' }}>
              Enregistrer
            </Button>
          </Collapse>
        </Grid>
      </Grid>

      <Dialog open={clientInfoOpen} onClose={handleCloseClientInfo} fullWidth maxWidth="sm">
        <DialogTitle>Informations du Client</DialogTitle>
        <DialogContent>
          {clientInfo ? (
            <div>
              <p>Nom: {clientInfo.nom}</p>
              <p>Email: {clientInfo.email}</p>
              <p>Mdp: {clientInfo.mot_de_passe}</p>
              <p>Téléphone: {clientInfo.telephone}</p>
            </div>
          ) : (
            <p>Chargement...</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClientInfo}>Fermer</Button>
        </DialogActions>
      </Dialog>

      <DemandeActions
        open={actionsOpen}
        onValidated={reloadDemandes}
        onClose={handleCloseActions}
        selectedIds={selectedIds}
        selectedEmail={selectedEmail}
      />
    </>
  );
};
